import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {fetchJSONByGet} from '@/apis/index.js'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				date: [
					Moment().startOf('day').add(-3, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(0, 'day').format('YYYY-MM-DD')
				],
				offerId: '',
				orderDirection: 'ASC',
				orderField: ''
			},
			tableData: [],
			namespace: '',
			fetchOnCreated: false,
			q: {
				startDate: Moment().startOf('day').add(-3, 'day').format('YYYY-MM-DD'),
				endDate: Moment().startOf('day').add(0, 'day').format('YYYY-MM-DD')
			},
		}
	},
	created() {
		const {
			startDate,
			endDate
		} = this.$route.query
		if (startDate && endDate && /\d{4}-\d{2}-\d{2}/.test(startDate) && /\d{4}-\d{2}-\d{2}/.test(endDate)) {
			this.queryForm.date = [startDate, endDate]
			this.q.startDate = startDate
			this.q.endDate = endDate
		}
		this.searchEvent(false)
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
					if (key === 'date') {
						const [startDate, endDate] = queryForm[key]
						query['startDate'] = startDate
						query['endDate'] = endDate
					} else {
						query[key] = queryForm[key]
					}
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent(showErr=true) {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		async getListUrl() {
			if (this.namespace === '') return
			return fetchJSONByGet('/adms_api_v0/adscom/pageOfferAdjustLog', {...this.q, ...this.page})
		},
		handleList(content) {
			this.tableData = content
		}
	}
}