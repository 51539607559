<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false">
					</el-date-picker>
				</form-item>
				<form-item label="OfferId:" style="width:200px;">
					<el-input v-model="queryForm.offerId" clearable size="mini"></el-input>
				</form-item>
				<form-item label="OrderDirection:" style="width:200px;">
					<el-select v-model="queryForm.orderDirection" size="mini">
						<el-option label="升序" value="ASC"></el-option>
						<el-option label="降序" value="DESC"></el-option>
					</el-select>
				</form-item>
				<form-item label="OrderField:" style="width:200px;">
					<el-select v-model="queryForm.orderField" clearable size="mini">
						<el-option label="Created At" value="createdAt"></el-option>
						<el-option label="ExecuteAt" value="executeAt"></el-option>
					</el-select>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<el-table :data="tableData" height="auto" border size="mini">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<table border style="margin: 20px;">
						<tbody>
							<tr>
								<th>OriginTerms</th>
								<td>{{scope.row.originTerms}}</td>
							</tr>
							<tr>
								<th>ReplaceTerms</th>
								<td>{{scope.row.replaceTerms}}</td>
							</tr>
							<tr>
								<th>Message</th>
								<td>{{scope.row.message}}</td>
							</tr>
						</tbody>
					</table>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="ID" width="80" />
			<el-table-column prop="offerId" label="Offer ID" />
			<el-table-column prop="status" label="状态">
				<template #default="scope">
					<div :class="{'text-success': scope.row.status, 'text-danger': !scope.row.status}">{{ scope.row.status ? '成功' : '失败' }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdAt" label="创建时间">
				<template #default="scope">
					{{scope.row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss', false)}}
				</template>
			</el-table-column>
			<el-table-column prop="executeAt" label="执行时间">
				<template #default="scope">
					{{scope.row.executeAt | formatDate('YYYY-MM-DD HH:mm:ss', false)}}
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
	</el-card>
</template>

<script>
	import config from './minixs/term-adjust-log.js'
	import Moment from 'moment'
	export default {
		mixins: [config],
		inject: ['adminLayout'],
		data() {
			return {
				fullScreen: false,
				namespace: 'jscx',
			}
		},
		methods: {
		}
	}
</script>

<style>
</style>